<script setup lang="ts">
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

const style = cva(
  [
    'font-medium bg-gradient-to-b flex items-center justify-center',
    'transition-all duration-300 hover:duration-100 transform active:translate-y-0.5',
  ],
  {
    variants: {
      intent: {
        main: 'from-main-500 to-main-550 text-white bordershadow-main-highlight hover:bordershadow-lg-main ',
        normal: 'from-gray-50 to-[#F6F6F6] text-gray-900 bordershadow-light-highlight hover:bordershadow-medium',
      },
      size: {
        sm: 'text-sm px-3 py-1.5 rounded',
        normal: 'text-base px-3 py-1.5 rounded-md',
        lg: 'text-base px-6 py-3 rounded-lg',
      },
    },
  },
)
type StyleProps = VariantProps<typeof style>

const props = withDefaults(
  defineProps<{
    as?: 'button' | 'link'
    to?: string
    intent?: StyleProps['intent']
    size?: StyleProps['size']
  }>(),
  {
    as: 'button',
    intent: 'normal',
    size: 'normal',
  },
)

let type = 'button'
if (props.as === 'link') {
  if (props.to?.includes('://')) {
    type = 'a'
  } else {
    type = resolveComponent('NuxtLink') as string
  }
}
</script>

<template>
  <component :is="type" :class="style({ intent, size, class: $props.class as string })" :href="to">
    <slot />
  </component>
</template>

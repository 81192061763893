<script setup lang="ts">
import { useNav } from './nav/nav'

const nav = await useNav()

const lp = useLocalePath()
const { fetchFooter } = useFooter()

const { data } = await useAsyncData(() => fetchFooter())

console.log('data', data)
</script>

<template>
  <Container v-if="data" as="footer" class="w-full border-t border-gray-200">
    <div class="screen-content grid h-full grid-cols-1 gap-y-4 py-4 sm:grid-cols-2 sm:px-16 sm:py-8 lg:grid-cols-4">
      <div class="flex flex-col items-start justify-between gap-y-4 text-left lg:col-span-1">
        <LayoutLogo schema="black" class="mb-4 h-8 opacity-75" />
        <div class="text-md flex flex-col space-y-3 text-gray-500">
          <span>{{ $t('footer.location.address.postal_code') }}</span>
          <span>{{ $t('footer.location.address.street') }}</span>
          <span>{{ $t('footer.location.address.country') }}</span>
        </div>
        <div class="flex flex-row gap-2">
          <Icon size="20" name="flag:ro-4x3" />
          <Icon size="20" name="flag:eu-4x3" />
        </div>
        <div class="flex flex-col space-y-4">
          <LayoutFooterSocial />
          <Paragraph size="small" class="!text-gray-500"> © 2016-{{ new Date().getFullYear() }}, Webamboos </Paragraph>
        </div>
      </div>

      <div class="mx-6 flex flex-col justify-between gap-8 text-left md:mx-16 md:flex-row lg:col-span-3 lg:mx-28">
        <div class="w-full md:w-auto">
          <LayoutFooterHeading>
            {{ $t('footer.company') }}
          </LayoutFooterHeading>
          <ul class="mt-3 flex flex-col gap-2">
            <template v-for="item of nav">
              <li v-if="item.type === 'internal'">
                <LayoutFooterLink :to="item.to" type="internal">
                  {{ item.label }}
                </LayoutFooterLink>
              </li>
              <template v-if="item.type === 'subnav'">
                <li>
                  <LayoutFooterLink :to="item.highlight.to" type="internal">
                    {{ item.highlight.label }}
                  </LayoutFooterLink>
                </li>
                <li v-for="subItem of item.links">
                  <LayoutFooterLink :to="subItem.to" type="internal">
                    {{ subItem.label }}
                  </LayoutFooterLink>
                </li>
              </template>
            </template>
            <li>
              <LayoutFooterLink :to="lp('/contact')" type="internal">
                {{ $t('header.nav.contact') }}
              </LayoutFooterLink>
            </li>
          </ul>
        </div>

        <div class="w-full md:w-auto">
          <LayoutFooterHeading>
            {{ $t('footer.our_work') }}
          </LayoutFooterHeading>
          <ul class="mt-3 flex flex-col gap-2">
            <template v-for="item of nav.filter((i) => i.label === 'Our work')">
              <li v-for="subItem of item.links" :key="subItem.to">
                <LayoutFooterLink :to="subItem.to" type="internal">
                  {{ subItem.label }}
                </LayoutFooterLink>
              </li>
            </template>
          </ul>
        </div>

        <div class="w-full md:w-auto">
          <LayoutFooterHeading>
            {{ $t('footer.services') }}
          </LayoutFooterHeading>
          <ul class="mt-3 flex flex-col gap-2">
            <template v-for="item of nav.filter((i) => i.label === 'Services')">
              <li v-for="subItem of item.links" :key="subItem.to">
                <LayoutFooterLink :to="subItem.to" type="internal">
                  {{ subItem.label }}
                </LayoutFooterLink>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </Container>

  <Container class="flex border-t border-gray-200 bg-gray-50 py-12">
    <div class="container mx-auto flex flex-wrap justify-center gap-8 md:gap-16 lg:gap-36">
      <NuxtLink :to="badge.url" target="_blank" v-for="badge in data?.badges" :key="badge.name">
        <NuxtImg class="w-16 sm:w-20 md:w-24" :src="badge.image.url" :alt="badge.name" />
      </NuxtLink>
    </div>
  </Container>
</template>

<script setup lang="ts">
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import WrapBalancer from "vue-wrap-balancer";

const style = cva([], {
  variants: {
    size: {
      hero: "text-4xl md:text-[4rem] lg:text-[5rem] font-bold tracking-[-0.175rem] lg:tracking-[-0.35rem] leading-none",
      display:
        "text-3xl md:text-[3.75rem] lg:text-[4.5rem] font-bold leading-[1.1] tracking-[-0.15rem] lg:tracking-[-0.3rem]",
      large:
        "text-3xl md:text-[2.5rem] lg:text-[3.25rem] font-bold leading-[1.2] tracking-tighter pr-1 lg:tracking-[-0.2rem]",
      big: "text-2xl md:text-[2rem] font-bold leading-[1.2] lg:tracking-[-0.1rem]",
      medium: "text-2xl font-bold leading-[1.2] text-gray-950",
      small: "text-base font-medium text-gray-950",
      caption: "text-base font-medium text-main-600",
    },
    gradient: {
      custom: "text-transparent bg-clip-text bg-gradient-to-b",
      gray: "text-transparent bg-clip-text bg-gradient-to-b from-gray-950 to-gray-600",
      main: "text-transparent bg-clip-text bg-gradient-to-b from-main-700 to-main-500",
    },
  },
});
type StyleProps = VariantProps<typeof style>;

withDefaults(
  defineProps<{
    size?: StyleProps["size"];
    gradient?: StyleProps["gradient"];
    as?: keyof HTMLElementTagNameMap;
  }>(),
  {
    size: "medium",
    as: "h2",
  }
);
</script>

<template>
  <component
    :is="as"
    :class="style({ class: $props.class as string, size, gradient })"
  >
    <WrapBalancer>
      <slot />
    </WrapBalancer>
  </component>
</template>
